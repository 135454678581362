.theme-switch {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  margin: 0 10px;
  justify-content: flex-end;
}

.theme-switch .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 10px;
}

.theme-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 50px;
}

.theme-switch .slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.theme-switch input:checked + .slider {
  background-color: #2196f3;
}

.theme-switch input:checked + .slider:before {
  transform: translateX(26px);
}

.theme-switch span {
  font-size: 0.9rem;          /* Reduce the size of the social title text */
  font-weight: bold;
  color: var(--text-color);
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {

  .theme-switch span {
    font-size: 0.75rem;          /* Reduce the size of the social title text */
  }

  .theme-switch .switch {
    width: 30px;
    height: 17px;
    margin-right: 5px;
  }

  .theme-switch input:checked + .slider:before {
    transform: translateX(13px);
  }

  .theme-switch .slider:before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
}