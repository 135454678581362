.social-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 10px;
  text-decoration: none;
  color: white;
}

.social-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: inherit;
  overflow: hidden;  /* Ensures the image doesn't overflow */
}

.social-icon {
  object-fit: contain; /* Ensure the image is contained within the circle */
}

.social-title {
  margin-top: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--text-color);
}

/* Hover effect to scale the entire social link */
.social-link:hover .social-icon-container {
  transform: scale(1.1);  /* Scale up the circle on hover */
  transition: transform 0.3s ease-in-out;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .switch {
    font-size: 0.8rem;           /* Make the switch slightly smaller */
  }

  .social-links {
    gap: 10px;                   /* Reduce the space between social icons */
  }

  .social-icon-container {
    width: 30px;                 /* Make the social icons smaller */
    height: 30px;
  }

  .social-title {
    font-size: 0.75rem;          /* Reduce the size of the social title text */
  }
}