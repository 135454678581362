.content {
    display: flex;
    flex-direction: column;    /* Stack the header and social-links vertically */
    align-items: flex-start;   /* Align the content to the left */
    width: 100%;               /* Ensure the container takes up full width */
    justify-content: center;
    align-items: center;
    flex-grow: 1;  
} 

.content .description {
  max-width: 400px; /* Limit the width of the text block */
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  justify-content: center;
  align-items: center;          /* Align items vertically, sticking to the bottom */
}

.description {
  font-size: 1.1rem; /* Adjust font size to your preference */
  line-height: 1.6; /* Improves readability by adding space between lines */
  color: var(--text-color);
  margin: 0 auto 20px; /* Center the text and add spacing below */
  padding: 10px 20px; /* Add some padding around the text */
  text-align: left; /* Align text to the left or justify */
}

@media (max-width: 768px) {
  .description {
    font-size: 1rem; /* Slightly smaller font size on smaller screens */
    padding: 10px 15px; /* Adjust padding for mobile screens */
  }
}