.App {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  min-height: calc(var(--vh, 1vh) * 100);  /* Dynamically set height */
  position: relative; /* Allow positioning of the ThemeSwitch */
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: Arial, sans-serif;
  justify-content: flex-start; /* Align items at the top */
  padding: 20px;              /* Optional: Add some padding */
  box-sizing: border-box;     /* Ensure padding is included in the element's total width and height */
}

/* Position the ThemeSwitch in the top-right corner */
.theme-switch {
  top: 20px;
  right: 20px;
  margin-bottom: 20px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}